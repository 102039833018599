import { styled } from "styled-components";
import { useEffect, useRef, useState } from "react";
import selectFile from "../controllers/selectFile";

import LoadingSection from "./LoadingSection";

import { AiOutlineDown } from "react-icons/ai";
import PopupLocationSelector from "./PopupLocationSelector";
import CustomToggle from "./CustomToggle";
import ProfilePicture from "./ProfilePicture";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";
import compressAndUploadFile from "../zekFrontendToolkit/mediaUpload/controller/compressAndUploadFile";
import getImageURL from "../zekFrontendToolkit/mediaUpload/controller/getImageURL";
// import PopupEmojiSelector from "./PopupEmojiSelector";

import { FaDice, FaDiceOne, FaDiceTwo } from "react-icons/fa";
import { BsDice2 } from "react-icons/bs";
import getRandomLivingThingEmoji from "../controllers/getRandomLivingThingEmoji";

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  width: 38vw;
  font-size: 13px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 15px 15px;
  font-weight: 600;

  @media (max-width: 900px) {
    width: 62vw;
  }
`;

const Textarea = styled.textarea`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  /* text-align: center; */
  font-family: "Inter";
  resize: none;

  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const Input = styled.input`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  /* text-align: center; */
  font-family: "Inter";
  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const PopupSelectInput = styled.div`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  font-family: "Inter";
  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SubmitButton = styled.div`
  background: var(--translucent);
  padding: 15px 50px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  font-size: 21px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  transition: 0.25s ease-out;

  @media (max-width: 900px) {
    width: 90vw;
  }

  &:hover {
    transform: scale(0.9);
  }
`;

const Label = styled.div`
  /* margin-bottom: -15px; */
  opacity: 0.5;
`;

const SelectedImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  /* height: auto; */
  /* height: 300px; */
  border-radius: 10px;
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */

  width: 15vw;

  @media (max-width: 900px) {
    width: calc((90vw - 80px) / 2);
  }
`;

const PickRandomEmoji = styled(Button)`
  /* width: 300px; */

  width: 50px;
`;

const ImageUploadButtons = styled.div`
  /* width: 300px; */

  display: flex;
  flex-direction: row;
  /* width: 100%; */
  gap: 10px;
  justify-content: space-between;
`;

export default function ProfileEditor({ callback }) {
  const { loggedInUser, refreshUser, showPopup, goTo } = useZekToolkit();
  const [bio, setBio] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);
  const [githubHandle, setGithubHandle] = useState(null);
  const [websiteLink, setWebsiteLink] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [hireable, setHireable] = useState(false);
  const [gender, setGender] = useState(false);
  const [username, setUsername] = useState(false);
  const [privacy, setPrivacy] = useState("PUBLIC");
  const [loading, setLoading] = useState(false);

  const [profilePicture, setProfilePicture] = useState(null);

  const inputRef = useRef(null);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.bio) setBio(loggedInUser.bio);
      if (loggedInUser.githubHandle) setGithubHandle(loggedInUser.githubHandle);
      if (loggedInUser.websiteLink) setWebsiteLink(loggedInUser.websiteLink);
      if (loggedInUser.country) setCountry(loggedInUser.country);
      if (loggedInUser.state) setState(loggedInUser.state);
      if (loggedInUser.city) setCity(loggedInUser.city);
      if (loggedInUser.hireable) setHireable(loggedInUser.hireable);
      if (loggedInUser.privacy) setPrivacy(loggedInUser.privacy);
      if (loggedInUser.gender) setGender(loggedInUser.gender);
      if (loggedInUser.username) setUsername(loggedInUser.username);
      if (loggedInUser.profilePicture) {
        setProfilePicture(loggedInUser.profilePicture);
      } else {
        setProfilePicture({ type: "EMOJI", data: getRandomLivingThingEmoji() });
      }
    }
  }, [loggedInUser]);

  const resizeTextArea = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [bio]);

  if (loading || !loggedInUser) return <LoadingSection />;

  let profilePicObj = selectedImages ? (
    <ShowSelectedImage fileObject={selectedImages[0]} />
  ) : (
    <ProfilePicture data={profilePicture} size="120px" />
  );

  return (
    <>
      <Inputs>
        <ImageUploadSection>
          {profilePicObj}

          <ImageUploadButtons>
            <ImageUploadButton onClick={chooseImage}>
              {selectedImages ? "Change" : "Upload"} Picture
            </ImageUploadButton>

            {/* <ImageUploadButton onClick={doEmojiPickerPopup}>
              Select Emoji
            </ImageUploadButton> */}
            <PickRandomEmoji onClick={pickRandomEmoji}>
              <BsDice2 />
            </PickRandomEmoji>
          </ImageUploadButtons>
        </ImageUploadSection>

        <InputItem>
          <Label>Username</Label>
          <Input
            placeholder="username"
            value={username}
            onChange={({ target }) => {
              setUsername(target.value.toLowerCase());
            }}
          ></Input>
        </InputItem>

        <InputItem>
          <Label>Bio</Label>
          <Textarea
            ref={inputRef}
            placeholder="Describe Yourself and your skills"
            value={bio}
            onChange={({ target }) => {
              setBio(target.value);
            }}
          ></Textarea>
        </InputItem>

        <InputItem>
          <Label>Github Username</Label>
          <Input
            placeholder="Type your Github Username"
            value={githubHandle}
            onChange={({ target }) => {
              setGithubHandle(target.value);
            }}
          />
        </InputItem>

        <InputItem>
          <Label>Website Link</Label>
          <Input
            placeholder="Type Your Website Link"
            value={websiteLink}
            onChange={({ target }) => {
              setWebsiteLink(target.value);
            }}
          />
        </InputItem>

        <InputItem>
          <Label>Country</Label>
          <SelectInput
            onChange={setCountry}
            type="COUNTRY"
            placeholder={"Select Country"}
            value={country}
          />
        </InputItem>

        <CustomToggle
          name="Gender"
          options={[
            { label: "Male", value: "MALE" },
            { label: "Female", value: "FEMALE" },
          ]}
          value={gender}
          onChange={setGender}
        />

        <CustomToggle
          name="Privacy"
          options={[
            { label: "Public", value: "PUBLIC" },
            { label: "Private", value: "PRIVATE" },
          ]}
          value={privacy}
          onChange={setPrivacy}
        />
      </Inputs>

      <SubmitButton onClick={save}>Save</SubmitButton>
    </>
  );

  function pickRandomEmoji() {
    setProfilePicture({ type: "EMOJI", data: getRandomLivingThingEmoji() });
  }

  // function assignEmojiAsProfile(theEmoji) {
  //   setSelectedImages(null);
  //   setProfilePicture({ type: "EMOJI", data: theEmoji });
  //   goTo(-1)();
  // }

  // function doEmojiPickerPopup() {
  //   showPopup({
  //     component: <PopupEmojiSelector onChange={assignEmojiAsProfile} />,
  //   });
  // }

  function isValidLink(url) {
    var re =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return re.test(url);
  }

  function chooseImage() {
    selectFile({ onlyImage: true }).then(setSelectedImages);
  }

  async function uploadImage() {
    if (!selectedImages) return null;
    if (!selectedImages.length) return null;
    setLoading(true);
    console.log("Uploading Image");
    let newFile = await compressAndUploadFile({
      selectedFile: selectedImages[0],
      folder: "profile-picture",
    });
    setLoading(false);
    return newFile;
  }

  async function save() {
    if (!bio) return window.popupAlert("Bio is missing");
    let fileData = await uploadImage();

    if (websiteLink) {
      if (!isValidLink(websiteLink)) {
        return window.popupAlert("Invalid website link");
      }
    }

    let toSend = {
      changes: {
        onboarding: "basicInfo",
        profilePicture,
        bio,
        githubHandle,
        privacy,
        websiteLink,
        hireable,
        country,
        state,
        city,
        gender,
        username,
      },
    };

    if (fileData) {
      if (fileData.fileName) {
        toSend.changes.profilePicture = {
          type: "S3_UPLOAD",
          data: fileData.fileName,
        };
      }
    }

    setLoading(true);

    try {
      await serverLine.patch("/profile", toSend);

      refreshUser();

      setLoading(false);
      if (callback) callback(username);
      window.popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      window.popupAlert(e.message);
    }
  }
}

function ShowSelectedImage({ fileObject }) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    var fr = new FileReader();
    fr.onload = function (e) {
      setSrc(this.result);
    };
    fr.readAsDataURL(fileObject);
  }, [fileObject]);

  if (!fileObject) return;
  if (!src) return null;
  return <SelectedImage src={src} />;
}

const SelectInputContainer = styled.div`
  display: flex;
  width: 38vw;
  padding: 15px;
  cursor: pointer;
  border-radius: 15px;
  background: var(--translucent);
  justify-content: space-between;
  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const SelectInputText = styled.div``;
const SelectInputButton = styled.div``;

function SelectInput({
  value,
  placeholder,
  onChange,
  options,
  type,
  country,
  state,
}) {
  const { showPopup } = useZekToolkit();

  return (
    <SelectInputContainer
      onClick={() => {
        showPopup({
          title: placeholder,
          component: (
            <PopupLocationSelector
              state={state}
              country={country}
              type={type}
              value={value}
              options={options}
              onChange={onChange}
            />
          ),
        });
      }}
    >
      <SelectInputText>{value ? value : placeholder}</SelectInputText>

      <SelectInputButton>
        <AiOutlineDown />
      </SelectInputButton>
    </SelectInputContainer>
  );
}
