import { useEffect, useState } from "react";
import styled from "styled-components";
import UserPageTopSection from "./UserPageTopSection";
import LoadingSection from "./LoadingSection";
import StatOfUser from "./StatOfUser";
import getFollowStatus from "../controllers/getFollowStatus";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  flex-direction: column;
  display: flex;
  gap: 80px;
  width: 100%;
  align-items: center;
  text-align: center;
`;
const Details = styled.div`
  flex-direction: column;
  display: flex;
  gap: 25px;
  margin-top: 50px;
  align-items: center;
  text-align: center;
`;
const Name = styled.h1`
  margin: 0;
  padding: 0;
`;
const SmallDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;
const Detail = styled.div``;
const StatSection = styled.div``;
const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (min-width: 800px) {
    gap: 20px;
  }
`;

const Warning = styled.div`
  margin-top: 0;
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 30px;
  opacity: 0.5;
  border-top: 1px solid var(--translucent);
  align-items: center;
`;

const Disclaimer = styled.div`
  font-size: 14px;
  padding: 25px;
  width: 100%;
  opacity: 0.5;
  margin: 30px 0;
`;

export default function UserPage({ pathSegments }) {
  const { loggedInUserID, refreshUser, loggedInUser } = useZekToolkit();
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  let username = pathSegments[0];

  useEffect(() => {
    setLoading(true);
    serverLine
      .get(`/profile/?username=${username}`)
      .then((newData) => {
        setData(newData);
        setLoading(false);
      })
      .catch(setError);
  }, [username, loggedInUserID]);

  if (error) return error.message;

  if (loading) return <LoadingSection />;

  let { user } = data;

  let followStatus = getFollowStatus(loggedInUser, user._id);

  let content = (
    <>
      <StatOfUser
        datesAndMinutes={data.datesAndMinutes}
        userStat={user.stat}
        user={user}
      />
    </>
  );

  if (loggedInUserID !== user._id)
    if (user.privacy === "PRIVATE") {
      if (followStatus !== "POSITIVE")
        content = <Warning>This Account is private</Warning>;
    }

  return (
    <Container>
      <UserPageTopSection followStatus={followStatus} user={user} />
      {content}

      <Disclaimer>
        The projects stat, time stat & language stat is only retained for 2
        months.
      </Disclaimer>
    </Container>
  );
}
