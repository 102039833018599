import styled from "styled-components";
import HorizontalBarGraph from "./statLibrary/HorizontalBarGraph";
import hozStatDataProcessor from "../controllers/hozStatDataProcessor";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
const Adder = styled.div`
  display: flex;
  gap: 15px;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-between;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  width: 100%;
  border-radius: 15px;
  background: var(--translucent);
`;
const Input = styled.input`
  border: none;
  padding-left: 15px;
  height: 50px;
  font-size: 17px;
  outline: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color);
  background: transparent;
`;
const SecretTitleInput = styled.input``;
const Button = styled.button`
  height: 50px;
  width: 50px;
  border: none;
  color: var(--color);
  border-radius: 15px;
  background: var(--translucent);

  cursor: pointer;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export default function ProjectSpecificStat({ rawData, projectName }) {
  return (
    <Container>
      <HorizontalBarGraph
        title={projectName + ` Stat`}
        rawData={rawData}
        dataProcessor={({ timeSpan, xAxis, rawData }) => {
          return hozStatDataProcessor({
            timeSpan,
            xAxis,
            rawData,
            project_filter: projectName,
          });
        }}
      />
    </Container>
  );
}
