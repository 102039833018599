import styled from "styled-components";
import Leaderboard from "./Leaderboard";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  align-items: center;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const SubTitle = styled.h3`
  text-transform: capitalize;
  margin: 0;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  margin: 0px 0;
  background: var(--translucentHard);
`;

export default function LeaderboardPage() {
  const { loggedInUserID } = useZekToolkit();
  if (!loggedInUserID) return "Login to continue";

  return (
    <Container>
      <SubTitle>Leaderboard Based on Daily Avg</SubTitle>
      <Leaderboard />
    </Container>
  );
}
