import styled from "styled-components";
import { useEffect, useState } from "react";
import LoadingSection from "./LoadingSection";
import FollowerOrFollowingBox from "./FollowerOrFollowingBox";
import UserPageTopSection from "./UserPageTopSection";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px;
  align-items: center;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const SubTitle = styled.h3`
  text-transform: capitalize;
  margin: 0;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  margin: 0px 0;
  background: var(--translucentHard);
`;

export default function ShowFollowingsOrFollowing({ type, username }) {
  const { loggedInUserID, loggedInUser } = useZekToolkit();

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(null);

    if (loggedInUserID) {
      serverLine
        .get(
          `/followers-followings/?username=${username}&type=${type.toUpperCase()}`
        )
        .then((newData) => {
          setData(newData);
        });
    }
  }, [type, loggedInUserID]);

  if (!loggedInUserID) return "Login To Continue";

  if (!data) return <LoadingSection />;

  if (!loggedInUser) return <LoadingSection />;

  return (
    <Container>
      <SubTitle>{type}</SubTitle>
      <List>
        {data.list.map((item) => (
          <FollowerOrFollowingBox
            user={item}
            isFollower={
              type.toUpperCase() == "FOLLOWERS" &&
              data.user._id == loggedInUserID
            }
          />
        ))}
      </List>
    </Container>
  );
}
