import LoggedInHome from "./LoggedInHome";
import LoggedOutHomePage from "./pages/LoggedOutHomePage";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";

export default function Home() {
  const { loggedInUserID } = useZekToolkit();

  if (!loggedInUserID) return <LoggedOutHomePage />;

  return <LoggedInHome />;
}
