import { useContext } from "react";
import styled from "styled-components";
import Context from "../Context";
import { useEffect, useState } from "react";
import LoadingSection from "./LoadingSection";

import FollowButton from "./FollowButton";
import Link from "./Link";
import toMinsOrHours from "../controllers/toMinOrHours";
import ProfilePicture from "./ProfilePicture";
import capitalizeFirstLetter from "../controllers/capitalizeFirstLetter";
import getLanguageString from "../controllers/getLanguageString";
import shortenTheName from "../controllers/shortenTheName";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: var(--translucent);
  padding: 25px;
  border-radius: 15px;
  gap: 15px;
`;
const Left = styled.div`
  display: flex;

  flex-direction: column;
  gap: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const Name = styled.span`
  font-weight: 900;
  font-size: 20px;
`;
const Username = styled.span`
  /* font-weight: 900; */
  font-size: 15px;
  opacity: 0.5;
`;
const Buttons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 5px;
  flex-direction: row;

  @media (max-width: 900px) {
    /* justify-content: center; */
  }
`;
const Button = styled.div`
  background-color: var(--translucent);
  font-size: 18px;
  padding: 10px 25px;
  color: var(--color);
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  border: none;

  &:hover {
    background-color: var(--color);
    color: var(--bgColor);
  }
`;
const NameLine = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  gap: 10px;

  @media (max-width: 900px) {
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    /* align-items: center; */
  }
`;

const SubTitle = styled.div`
  font-size: 15px;
  text-transform: capitalize;
  opacity: 0.5;
  line-height: 25px;

  @media (max-width: 900px) {
    /* text-align: center; */
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* align-items: center; */

  @media (max-width: 900px) {
    /* flex-direction: column; */
    /* align-items: center; */
  }
`;

const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--translucent);
`;

const ProfileTop = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 25px;
`;

export default function FollowerOrFollowingBox({ user, isFollower }) {
  // return JSON.stringify(user.username);
  const [removed, setRemoved] = useState(false);
  const { loggedInUser, loggedInUserID } = useZekToolkit();

  if (!user) return "Invalid User";
  if (removed) return [];

  let subTitle1 = "";
  let subTitle2 = "";

  if (user.bio) subTitle1 = user.bio;

  if (user.dailyAvgScoreInMins)
    subTitle2 +=
      "Daily Avg: " +
      toMinsOrHours({ unparsedMinutes: user.dailyAvgScoreInMins });

  let langStr = getLanguageString(user, 2);

  if (langStr) subTitle2 += " ・ " + langStr;

  let buttons = (
    <Buttons>
      <FollowButton
        CustomContainer={Button}
        latestLoggedInUser={loggedInUser}
        user={user}
      />
      {isFollower ? <Button onClick={removeFollower}>Remove</Button> : null}
    </Buttons>
  );

  if (loggedInUserID == user._id) buttons = null;

  return (
    <Container>
      <Link href={"/" + user.username}>
        <Row>
          <ProfileTop>
            <ProfilePicture
              borderRadius="10px"
              size={"60px"}
              data={user.profilePicture}
            />
            <NameLine>
              <Name>{shortenTheName(user.name, 15)}</Name>
              <Username> @{user.username}</Username>
            </NameLine>
          </ProfileTop>

          <Left>
            <SubTitle>{subTitle1}</SubTitle>
            <Hr />
            {subTitle2 ? (
              <>
                <SubTitle>{subTitle2}</SubTitle>
                <Hr />
              </>
            ) : null}
          </Left>
        </Row>
      </Link>

      {buttons}
    </Container>
  );

  function removeFollower() {
    setRemoved(true);
    serverLine.post("/remove-follower", { userID: user._id });
  }

  function makeNameShorter() {
    user.name = user.name.toLowerCase();
    let nameSplit = user.name.split(" ");
    let firstName = nameSplit[0];
    let lastName = nameSplit[1];

    let fullName = capitalizeFirstLetter(firstName);

    if (lastName) {
      fullName +=
        " " + lastName[0].toUpperCase() + lastName[lastName.length - 1] + ".";
    }

    return fullName + " ";
  }
}
