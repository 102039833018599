import storeAuthCred from "../zekFrontendToolkit/auth/storeAuthCred";
import universalLocalStorage from "../zekFrontendToolkit/universalLocalStorage";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import LoadingSection from "./LoadingSection";

import { useEffect } from "react";

export default function ExtensionLogin({ queryParams }) {
  const { refreshUser, globalState, setGlobalState, goTo, setThemeOverride } =
    useZekToolkit();

  useEffect(() => {
    doExtAuth();
  }, []);

  async function doExtAuth() {
    let userID = queryParams["userID"];
    let token = queryParams["token"];
    let bgColor = queryParams["bgColor"];
    let color = queryParams["color"];

    if (userID && token) {
      let extensionTheme = getExtensionTheme({ bgColor, color });

      setThemeOverride(extensionTheme);

      await storeAuthCred({ token, userID, namespace: "paratime" });
      await refreshUser();

      await universalLocalStorage.setItem("extension-login", true); //So that open link works properly

      let newState = { ...globalState };
      newState.isInsideExtension = true;

      setGlobalState(newState);

      goTo("/")();
    }
  }

  function getExtensionTheme({ bgColor, color }) {
    if (bgColor && color) {
      bgColor = "#" + bgColor;
      color = "#" + color;
      let translucent = "rgba(0,0,0,0.1)";
      let translucentHard = "rgba(0,0,0,0.2)";

      let luma = getLuma(bgColor);

      if (luma < 100) {
        translucent = "rgba(255,255,255,0.05)";
        translucentHard = "rgba(255,255,255,0.2)";
      }

      return {
        color,
        bgColor,
        translucent,
        translucentHard,
      };
    } else {
      return null;
    }
  }

  // return `${searchParams.get("userID")}  ${searchParams.get("token")}`;

  return <LoadingSection />;

  function getLuma(c) {
    var c = c.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    return luma;
  }
}
