import styled from "styled-components";
import { useEffect, useState } from "react";
import LoadingSection from "./LoadingSection";
import serverLine from "../zekFrontendToolkit/network/serverLine";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import LoadingSpinner from "../zekFrontendToolkit/ui/LoadingSpinner";
import InfoBox from "../zekFrontendToolkit/ui/InfoBox";
import { MdDone } from "react-icons/md";
import ZekButton from "../zekFrontendToolkit/inputUI/ZekButton";
import { GrGoogle } from "react-icons/gr";
import universalLocalStorage from "../zekFrontendToolkit/universalLocalStorage";

const Button = styled.button`
  border: 1px solid;
  background: transparent;
  display: flex;
  flex-direction: row;
  color: var(--color);
  gap: 10px;
  align-items: center;
  padding: 14px 20px;
  cursor: pointer;
  border-radius: 25px;
`;

const ErrorBox = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
`;

const Container = styled.div``;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 38vw;
  background-color: var(--translucentLight);
  border: 1px solid var(--translucent);
  border-radius: 10px;
  padding: 25px;
  margin-top: 100px;
  padding-bottom: 40px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.div`
  font-size: 45px;
  padding: 20px;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--translucentLight);
  background: var(--translucent);
  border-radius: 100px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 18px;
`;

const Description = styled.div`
  opacity: 0.5;
  font-size: 15px;
`;

function PromptExtensionAuth() {
  const { loggedInUserID, doGoogleLogin } = useZekToolkit();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  if (loading) return <LoadingSpinner />;

  if (status) {
    return (
      <Container>
        <Box>
          <Icon>
            <MdDone />
          </Icon>
          <Text>
            <Title>The extension has been authenticated.</Title>
            <Description>You can now close this window</Description>
          </Text>
        </Box>
      </Container>
    );
  }

  if (loggedInUserID) {
    return (
      <Container>
        <Box>
          <Text>
            <Title>Extension Authentication</Title>
            <Description>
              Click the following button to authenticate the extension
            </Description>
          </Text>

          <ZekButton
            style={{ width: "300px" }}
            onClick={sendAuthDataToExtension}
          >
            Authenticate Extension
          </ZekButton>
        </Box>
      </Container>
    );
  } else {
    return (
      <Container>
        <Box>
          <Text>
            <Title>Login to authenticate extension</Title>
          </Text>

          <ZekButton
            style={{ width: "200px" }}
            icon={<GrGoogle />}
            onClick={doGoogleLogin}
          >
            Login with google
          </ZekButton>
        </Box>
      </Container>
    );
  }

  async function sendAuthDataToExtension() {
    setLoading(true);

    let localExtensionServer = "http://localhost:65510";
    let userID = await universalLocalStorage.getItem("paratime-userID");
    let token = await universalLocalStorage.getItem("paratime-token");
    let data = await serverLine.post(
      `${localExtensionServer}/receive-auth-data-from-browser`,
      { userID, token }
    );

    console.log("ext auth data", data);
    setLoading(false);
    setStatus(true);
  }
}

export default PromptExtensionAuth;
