import { styled } from "styled-components";
import BrandContainer from "../BrandContainer";
import ProfileEditor from "../ProfileEditor";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  gap: 25px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
`;

const Button = styled.div`
  width: 38vw;
  font-size: 21px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Input = styled.textarea`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  font-family: "Inter";
  resize: none;

  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const SubmitButton = styled.div`
  background: var(--translucent);
  padding: 15px 50px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  font-size: 21px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  padding: 0;
  text-align: center;
`;

const SubTitle = styled.div`
  opacity: 0.5;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  width: 62vw;
`;

export default function BasicInfo() {
  let { globalState } = useZekToolkit();
  let { isInsideExtension } = globalState;

  return (
    <Container>
      {isInsideExtension ? null : (
        <BrandContainer style={{ marginTop: "25px" }} />
      )}
      <br />
      <Title>Please! Complete your profile</Title>
      <SubTitle>
        Better the profile, higher the chances of getting hired.
      </SubTitle>
      <br />
      <ProfileEditor />
    </Container>
  );
}
