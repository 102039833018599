import { useState } from "react";
import styled from "styled-components";
import { AiOutlineDelete, AiOutlineLock } from "react-icons/ai";

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  border: none;
  padding-left: 15px;
  height: 50px;
  border-radius: 10px;
  font-size: 17px;
  outline: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color);
  background: var(--translucent);
`;

const Button = styled.button`
  padding: 10px 15px;
  border: none;
  color: var(--color);
  border-radius: 10px;
  background: var(--translucent);
  cursor: pointer;
  transition: 0.15s ease;

  &:hover {
    transform: scale(0.9);
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 100;
  font-size: 15px;
  margin-bottom: -10px;
  padding: 0;
`;

const NewItemCreator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const IconButton = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--translucent);
  cursor: pointer;
  transition: 0.15s ease;

  &:hover {
    transform: scale(0.9);
  }

  ${({ highlight }) => {
    if (highlight)
      return `
        background: var(--color);
        color: var(--bgColor);
  `;
  }}
`;

const IconButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export default function PostContentEditor({ values, setValues, heading }) {
  const [title, setTitle] = useState("");

  return (
    <Container>
      <Title>{heading}</Title>
      {values.length ? <Inputs>{renderInputs()}</Inputs> : null}

      <NewItemCreator>
        <Input
          onKeyUp={(e) => {
            console.log(e.key);
            if (e.key === "Enter") {
              postItem()();
            }
          }}
          placeholder="Write Here"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <Buttons>
          <Button onClick={postItem()}>Add</Button>
          <Button onClick={postItem(true)}>Add but keep it secret</Button>
        </Buttons>
      </NewItemCreator>
    </Container>
  );

  function postItem(isSecret) {
    return () => {
      let doc = { title };
      if (isSecret) {
        doc.isSecret = true;
      }
      let newValues = [...values, doc];
      setValues(newValues);
      setTitle("");
    };
  }

  function renderInputs() {
    let toRender = [];

    for (let i = 0; i < values.length; i++) {
      let item = values[i];
      toRender.push(
        <ItemContainer>
          <Input
            placeholder="Type Here"
            value={item.title}
            onChange={(target) => {
              onChange(i, target.value);
            }}
          />
          <IconButtons>
            <IconButton onClick={deleteItem(i)}>
              <AiOutlineDelete />
            </IconButton>
            <IconButton onClick={toggleSecret(i)} highlight={item.isSecret}>
              <AiOutlineLock />
            </IconButton>
          </IconButtons>
        </ItemContainer>
      );
    }

    return toRender;
  }

  function toggleSecret(i) {
    return () => {
      let newValues = [...values];
      let item = newValues[i];
      newValues[i].isSecret = item.isSecret ? false : true;
      setValues(newValues);
    };
  }

  function deleteItem(i) {
    return () => {
      let newValues = [...values];
      newValues.splice(i, 1);
      setValues(newValues);
    };
  }

  function onChange(index, newValue) {
    let newValues = [...values];
    newValues[index].title = newValue;
    setValues(newValues);
  }
}
