import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Btn = styled.div`
  padding: 25px 18px;
  font-size: 17px;
  border-radius: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  border-radius: 0;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid var(--translucent);
  /* height: 50px; */
  /* color: var(--accentColor); */
`;

export default function ZekMenuList({
  variant,
  label,
  items,
  style,
  btnStyle,
}) {
  let tgl = items.map(item => (
    <Btn
      variant={variant}
      style={btnStyle}
      key={item.value}
      onClick={item.onClick}
    >
      {item.label}
    </Btn>
  ));

  if (!label) return tgl;

  return <Container style={style}>{tgl}</Container>;
}
