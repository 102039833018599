import getDateWithSlash from "../getDateWithSlash";
import shortenTheName from "../shortenTheName";
import toMinsOrHours from "../toMinOrHours";

export default function verticalDataProcessorUserBased({
  dataPoints,
  startDate,
  timeSpanType,
  rawData,
}) {
  let proj = {};

  let monthsCovered = [];
  let yearsCovered = [];
  let weeksCovered = [];

  let date = new Date(startDate.toString());

  for (let i = 0; i < dataPoints; i++) {
    let slashDate = getDateWithSlash(date);
    processDate(slashDate);
    date.setDate(date.getDate() + 1);
  }

  function pushData({ name, username, item }) {
    if (!item) return;
    if (proj[name]) {
      proj[name].value += item;
      proj[name].label = `${toMinsOrHours({
        unparsedMinutes: proj[name].value,
      })} . ${shortenTheName(name, 15)}`;
    } else {
      proj[name] = {
        onClick: () => {
          window.goTo("/" + username)();
        },
        value: item,
        label: `${toMinsOrHours({
          unparsedMinutes: item,
        })} . ${shortenTheName(name, 15)}`,
      };
    }
  }

  function processDate(slashDate) {
    if (timeSpanType == "MONTH") {
      const [month, , year] = slashDate.split("/"); // Extract month and year
      const field = `${month}/${year}`; // Format as "MM/YYYY"

      if (monthsCovered.includes(field)) {
        return;
      }
    } else if (timeSpanType == "YEAR") {
      const year = slashDate.split("/")[2];

      if (yearsCovered.includes(year)) {
        return;
      }
    }

    for (let userID in rawData) {
      let user = rawData[userID];
      let name = user.userData.name;
      let username = user.userData.username;

      let item = 0;

      if (timeSpanType === "DATE") {
        if (user.partialDatesAndMinutes) {
          let val = user.partialDatesAndMinutes[slashDate];
          if (val) item = val;
        }
      } else if (timeSpanType == "MONTH") {
        if (user.monthAndMinutes) {
          const [month, , year] = slashDate.split("/"); // Extract month and year
          const field = `${month}/${year}`; // Format as "MM/YYYY"

          let val = user.monthAndMinutes[field];
          if (val) {
            item = val;
          }
        }
      } else if (timeSpanType == "YEAR") {
        if (user.yearAndMinutes) {
          const year = slashDate.split("/")[2];

          let val = user.yearAndMinutes[year];
          if (val) item = val;
        }
      } else if (timeSpanType == "WEEK") {
        if (user.partialDatesAndMinutes) {
          let val = user.partialDatesAndMinutes[slashDate];
          if (val) item = val;
        }
      }

      pushData({ name, item, username });
    }

    if (timeSpanType == "MONTH") {
      const [month, , year] = slashDate.split("/"); // Extract month and year
      const field = `${month}/${year}`; // Format as "MM/YYYY"
      monthsCovered.push(field);
    } else if (timeSpanType == "YEAR") {
      const year = slashDate.split("/")[2];
      yearsCovered.push(year);
    }
  }

  // console.log(proj);
  return proj;
}
