import { useContext, useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Context from "../Context";

import getFollowStatus from "../controllers/getFollowStatus";
import ConfettiExplosion from "react-confetti-explosion";
import ConfettiInstance from "./ConfettiInstance";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";

const Button = styled.button`
  background-color: var(--translucent);
  font-size: 15px;
  padding: 15px 25px;
  color: var(--color);
  display: flex;
  flex: 1;
  justify-content: center;
  /* width: 100%; */
  /* margin-top: 25px; */
  cursor: pointer;
  border-radius: 15px;
  border: none;
  /* font-weight: 900; */
  overflow: hidden;
  /* transition: 0.25s ease-in; */
  position: relative;

  &:hover {
    background: var(--translucentHard);
    color: var(--color);
  }
`;

export default function FollowButton({
  latestLoggedInUser,
  user,
  CustomContainer,
  customProps,
}) {
  const [status, setStatus] = useState("NEGATIVE");
  const { loggedInUserID, promptLogin } = useZekToolkit();
  const [isExploding, setIsExploding] = useState(false);
  // console.log(user.privacy);

  useEffect(() => {
    if (latestLoggedInUser) {
      setStatus(getInitialStatus());
    }
  }, []);

  if (!CustomContainer) CustomContainer = Button;

  let receiverUserID = user._id;
  if (!loggedInUserID) return null;

  if (receiverUserID === loggedInUserID) return null;

  if (status === "PENDING")
    return (
      <CustomContainer status={status} {...customProps} onClick={onClick}>
        Req. Pending
      </CustomContainer>
    );

  return (
    <CustomContainer status={status} {...customProps} onClick={onClick}>
      {status === "POSITIVE" ? "Following" : "Follow"}
      {isExploding ? <ConfettiInstance /> : null}
    </CustomContainer>
  );

  async function onClick() {
    if (!loggedInUserID) return window.popupAlert("Please! login to continue");

    if (status == "POSITIVE") {
      setStatus("NEGATIVE");
      setIsExploding(false);
    } else if (status == "NEGATIVE") {
      if (user.privacy == "PRIVATE") {
        setIsExploding(true);
        setStatus("PENDING");
      } else {
        setStatus("POSITIVE");
        setIsExploding(true);
      }
    } else if (status == "PENDING") {
      setStatus("NEGATIVE");
      setIsExploding(false);
    } else {
      if (user.privacy == "PRIVATE") {
        setStatus("PENDING");
        setIsExploding(true);
      } else {
        setIsExploding(true);
        setStatus("POSITIVE");
      }
    }

    await serverLine.post("/follow", { receiverUserID: receiverUserID });

    var parent = window.parent;

    if (parent) {
      parent.postMessage("REFRESH_STAT", "*");
    }
  }

  function getInitialStatus() {
    return getFollowStatus(latestLoggedInUser, user._id);
  }
}
