import { useEffect, useState } from "react";
import getDateWithSlash from "../controllers/getDateWithSlash";

import LoadingSection from "./LoadingSection";
import styled from "styled-components";
import { BsSend } from "react-icons/bs";
import PostContentEditor from "./PostContentEditor";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const Button = styled.button`
  padding: 15px 25px;
  border: none;
  font-size: 17px;
  color: var(--color);
  border-radius: 15px;
  background: var(--translucent);
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

const ButtonIcon = styled.div``;
const ButtonText = styled.div``;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
`;

export default function AccomplishmentAdder({ dateString }) {
  const { showPopup } = useZekToolkit();
  const [accomplishments, setAccomplishments] = useState([]);
  const [learnings, setLearnings] = useState([]);
  const [loading, setLoading] = useState(true);

  if (!dateString) dateString = getDateWithSlash(new Date());

  useEffect(() => {
    serverLine.get(`/content/?date=` + dateString).then((data) => {
      if (data) {
        if (data.accomplishments) setAccomplishments(data.accomplishments);
        if (data.learnings) setLearnings(data.learnings);
      }
      setLoading(false);
    });
  }, []);

  if (loading) return <LoadingSection />;

  return (
    <Container>
      <PostContentEditor
        setValues={setAccomplishments}
        values={accomplishments}
        heading="What have you accomplished today?"
      />
      <PostContentEditor
        setValues={setLearnings}
        values={learnings}
        heading="What have you learned today?"
      />
      <Buttons>
        <Button onClick={makePost}>
          <ButtonIcon>
            <BsSend />
          </ButtonIcon>
          <ButtonText> Save </ButtonText>
        </Button>
      </Buttons>
    </Container>
  );

  function makePost() {
    setLoading(true);
    serverLine
      .post(`/content`, {
        accomplishments,
        learnings,
        date: dateString,
      })
      .then(() => {
        window.popupAlert("Saved");
        setLoading(false);
        showPopup(null);
      });
  }
}
