import styled from "styled-components";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit.js";
import Header from "../components/Header.js";
import LoadingSection from "../components/LoadingSection.js";
import BasicInfo from "../components/onboarding/BasicInfo.js";
import FirstFollow from "../components/onboarding/FirstFollow.js";
import SelectAccountPrivacy from "../components/SelectAccountPrivacy.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100vh;
  width: 100vw;
  height: 100svh;
  padding-top: var(--mainPadding);
  overflow: hidden;
  overflow-y: scroll;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 50px;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  width: 38vw;

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 900px) {
    width: 90vw;
  }

  /* @media (max-width: 700px) {
    width: ${({ isInsideExtension }) => (isInsideExtension ? "100vw" : "90vw")};
  } */
`;

export default function RouteEncapsulation({ children }) {
  const { loggedInUserID, loggedInUser, globalState, setGlobalState } =
    useZekToolkit();

  let { isInsideExtension } = globalState;

  console.log("isInsideExtension", isInsideExtension);
  let path = window.location.pathname;

  let headerItem = loggedInUserID ? <Header /> : null;

  if (!loggedInUserID) {
    if (path == "/") return children;
    if (path == "/auth-redirect") return children;
  }

  let isOnboarding = false;

  if (loggedInUserID) {
    if (loggedInUser) {
      console.log(loggedInUser);

      if (!loggedInUser.onboarding) {
        isOnboarding = true;
        children = <BasicInfo />;
      }
      if (loggedInUser.onboarding) {
        if (!loggedInUser.onboarding.firstFollow) {
          isOnboarding = true;
          children = <FirstFollow />;
        }
        if (!loggedInUser.onboarding.basicInfo) {
          isOnboarding = true;
          children = <BasicInfo />;
        }
      }
      if (!loggedInUser.privacy) {
        isOnboarding = true;
        children = <SelectAccountPrivacy />;
      }
    } else {
      children = <LoadingSection />;
    }
  }

  if (isOnboarding) {
    headerItem = null;
  }

  return (
    <Container>
      {headerItem}
      <Main isInsideExtension={isInsideExtension}>{children}</Main>
      <br />
      <br />
    </Container>
  );
}
