import { Camera, CameraResultType } from '@capacitor/camera';

import { Capacitor } from '@capacitor/core';
import { nanoid } from 'nanoid';

async function selectFile(options) {
  const platform = Capacitor.getPlatform();

  try {
    if (platform == 'web') {
      return await webFileSelector(options);
    } else {
      return await appFileSelector(options);
    }
  } catch (e) {
    window.popupAlert(e.message);
    return null;
  }
}

async function appFileSelector() {
  const photo = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
  });

  console.log('photo', photo);

  // 2. Load the photo as a blob
  const response = await fetch(photo.webPath);
  console.log('response', response);
  let blob = await response.blob();
  console.log('blob', blob);
  blob.name = nanoid() + '.' + photo.format;
  return [blob];
}

function webFileSelector(options) {
  return new Promise(resolve => {
    let fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');

    fileInput.addEventListener('change', e => {
      resolve(e.target.files);
    });

    if (options) {
      if (options.onlyImage) {
        let acceptFiles = 'image/png, image/gif, image/jpeg';
        fileInput.setAttribute('accept', acceptFiles);
      }

      if (options.allowMultipleFiles) fileInput.setAttribute('multiple', true);

      if (options.allowFolders) fileInput.setAttribute('webkitdirectory', true);
    }

    fileInput.click();
  });
}

export default selectFile;
