import styled from "styled-components";
import openLink from "../controllers/openLink";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
`;

const Button = styled.div`
  cursor: pointer;
  /* flex: 1; */
  padding: 15px 15px;
  background: var(--translucent);
  border-radius: 15px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

export default function Settings() {
  const { loggedInUserID, goTo, logout } = useZekToolkit();

  if (!loggedInUserID) return "Login to continue";

  return (
    <Box>
      {loggedInUserID ? <Button onClick={doLogout}>Logout</Button> : null}

      <Button
        onClick={() => {
          goTo("/edit-profile")();
        }}
      >
        Edit Profile
      </Button>

      <Button onClick={openLink("https://arnavzek.com")}>
        About Developer
      </Button>
    </Box>
  );

  function doLogout() {
    doExtensionLogout();
    logout();
  }

  function doExtensionLogout() {
    var parent = window.parent;

    if (parent) {
      parent.postMessage("LOGOUT", "*");
    }
  }
}
