import styled from "styled-components";

import { FiPlay } from "react-icons/fi";
import { useEffect, useState } from "react";

import { AiOutlinePause } from "react-icons/ai";
import { BsStopFill } from "react-icons/bs";
import LoadingSection from "./LoadingSection";

import CircularProgress from "./CircularProgress";

import { nanoid } from "nanoid";
import getDateWithSlash from "../controllers/getDateWithSlash";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";
// import ImageCapture from "image-capture";

const Container = styled.div`
  display: flex;
  gap: 50px;
  /* width: 100%; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  padding-top: 50px;
`;

const BottomButtons = styled.div`
  padding: 25px 15px;
  padding-bottom: 50px;
  display: flex;
  /* flex-direction: column-reverse; */
  align-items: center;
  border-radius: 15px;
  gap: 50px;

  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 800px) {
    gap: 0;
    background-color: var(--translucent);
    display: flex;
    width: 18vw;
    height: calc(100vh - 100px);
    justify-content: space-between;
  }
`;

const Timer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: calc(70vw / 8);
  gap: 0;

  @media (min-width: 800px) {
    gap: 0;
    font-size: calc(18vw / 8.5);
  }
`;

const Time = styled.h1`
  /* height: 85px; */
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  gap: 50px;
  flex-direction: row;
`;

const EvenButton = styled.button`
  /* width: 50px; */
  height: 80px;
  cursor: pointer;
  border: none;
  width: 80px;
  border-radius: 500px;
  display: flex;
  font-size: 25px;
  background-color: var(--translucent);
  color: var(--color);
  gap: 15px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--translucentHard);
    color: var(--color);
  }
`;

const Message = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const MessageContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  align-items: center;
`;

const ProjectName = styled.div`
  opacity: 0.5;
  color: var(--color);
  text-transform: uppercase;
`;

export default function SessionPage({ queryParams }) {
  const [sessionStatus, setSessionStatus] = useState("OFF");
  const [sessionData, setSessionData] = useState(null);
  const { loggedInUserID, loggedInUser } = useZekToolkit();
  const [error, setError] = useState(false);
  const [timer, setTimer] = useState(60 * 60);

  const [totalDuration, setTotalDuration] = useState(0);
  const [sessionID, setSessionID] = useState(nanoid());

  useEffect(() => {
    return () => {
      document.title = `Paratime`;
    };
  }, []);

  if (typeof window !== "undefined") {
    window.sessionStatus = sessionStatus;
    window.totalDuration = totalDuration;
    window.timer = timer;
  }

  useEffect(() => {
    let duration = queryParams["duration"];
    setSessionStatus("ON");

    setTotalDuration(parseInt(duration) * 60);
    setTimer(parseInt(duration) * 60);
  }, []);

  useEffect(() => {
    fetchSessionData().then(setSessionData);
  }, [sessionStatus]);

  useEffect(() => {
    if (window.timerController) {
      window.clearInterval(window.timerController);
    }

    if (window.sessionPoster) {
      window.clearInterval(window.sessionPoster);
    }

    window.timerController = setInterval(() => {
      if (window.sessionStatus == "ON") {
        setTimer(window.timer - 1);

        if (window.timer <= 0) {
          window.clearInterval(window.timerController);

          setSessionStatus("ENDED");
        }
      }
    }, 1000);

    let intervalInMins = 5;

    intervalInMins = 0.1;

    window.sessionPoster = setInterval(() => {
      if (window.sessionStatus === "ON") {
        sendSessionRegisterReq();
      }
    }, 1000 * 60 * intervalInMins);

    return () => {
      window.clearInterval(window.timerController);
      window.clearInterval(window.sessionPoster);
    };
  }, []);

  let minAndSecs = getMinAndSeconds();

  if (!loggedInUserID)
    return (
      <MessageContainer>
        <Message>Login Required</Message>
      </MessageContainer>
    );

  if (error)
    return (
      <MessageContainer>
        <Message>{error}</Message>
      </MessageContainer>
    );

  if (!sessionData) return <LoadingSection />;

  if (sessionStatus === "ENDED")
    return (
      <MessageContainer>
        <Message>Your Session has completed</Message>
      </MessageContainer>
    );

  updateDocTitle();

  return (
    <Container>
      <CircularProgress
        progress={timer / totalDuration}
        backgroundColor={"var(--bgColor)"}
        color="var(--translucent)"
        color2="var(--color)"
        desktopSize={"15vw"}
        mobileSize={"70vw"}
        borderRadius={"500px"}
      >
        <Timer>
          <Time>{minAndSecs.mins}</Time>
          <Time>{minAndSecs.secs}</Time>
        </Timer>
      </CircularProgress>

      <Buttons>{getTimerButtons()}</Buttons>
    </Container>
  );

  function sendSessionRegisterReq() {
    let today = new Date();
    let timeIn24Hr = `${today.getHours()}:${today.getMinutes()}`;

    console.log(window.totalDuration, window.timer);

    let sessionItem = {
      sessionID,
      totalDuration: window.totalDuration - window.timer,
      timeIn24Hr: timeIn24Hr,
      date: getDateWithSlash(new Date()),
      projectName: "general",
    };

    serverLine.post(`/sessions`, {
      sessions: [sessionItem],
      date: getDateWithSlash(new Date()),
    });
  }

  function updateDocTitle() {
    document.title = `${minAndSecs.mins}:${minAndSecs.secs}`;
  }

  function getMinAndSeconds() {
    let mins = Math.floor(timer / 60);
    let secs = timer % 60;

    mins = mins.toString();
    secs = secs.toString();

    if (mins.length == 1) {
      mins = "0" + mins;
    }

    if (secs.length == 1) {
      secs = "0" + secs;
    }

    return { mins, secs };
  }

  async function fetchSessionData() {
    if (!loggedInUserID) return;
    let sessData = await serverLine.get(`/session`);

    return sessData;
  }

  function startSession() {
    setSessionStatus("ON");
  }

  function pauseSession() {
    setSessionStatus("OFF");
  }

  function endSession() {
    setSessionStatus("ENDED");
  }

  function getTimerButtons() {
    if (sessionStatus == "OFF" && timer > 0)
      return (
        <>
          <EvenButton onClick={startSession}>
            <FiPlay />
          </EvenButton>
          <EvenButton onClick={endSession}>
            <BsStopFill />
          </EvenButton>
        </>
      );

    return (
      <>
        <EvenButton onClick={pauseSession}>
          <AiOutlinePause />
        </EvenButton>
        <EvenButton onClick={endSession}>
          <BsStopFill />
        </EvenButton>
      </>
    );
  }
}
