import styled from "styled-components";

import tinycolor from "tinycolor2";
import { useEffect, useState } from "react";
import getImageURL from "../zekFrontendToolkit/mediaUpload/controller/getImageURL";

const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 100px;
  object-fit: cover;
`;

const EmojiImage = styled.div`
  height: 100px;
  width: 100px;
  font-size: 80px;
  display: flex;
  border-radius: 100px;
  border: 1px solid var(--translucent);
  justify-content: center;
  align-items: center;
  background-color: var(--translucentLight);
`;

export default function ProfilePicture({
  size = 100,
  data,
  onClick,
  borderRadius = "500px",
  style = {},
}) {
  const [bgColor, setBgColor] = useState("var(--translucent)");
  useEffect(() => {
    if (data) {
      if (data.type == "EMOJI") {
        figureOutBgColor2({ setBgColor, emoji: data.data });
      }
    }
  }, [data]);

  style.borderRadius = borderRadius;

  let fontSize = calcEmojiSize();

  if (size) {
    style.height = size;
    style.width = size;
    style.borderRadius = borderRadius;
    style.fontSize = fontSize;
  }

  if (onClick) style.cursor = "pointer";

  if (!data)
    return (
      <ProfileImage
        onClick={onClick}
        style={style}
        src={getImageURL(null, true)}
      />
    );

  if (data.type == "EMOJI")
    return (
      <EmojiImage
        onClick={onClick}
        style={{
          ...style,
          backgroundColor: bgColor,
        }}
      >
        {data.data}
      </EmojiImage>
    );

  return (
    <ProfileImage onClick={onClick} style={style} src={getImageURL(data)} />
  );

  function calcEmojiSize() {
    let theSize = size;

    if (typeof theSize == "string") {
      theSize = parseInt(theSize);
    }

    return theSize * 0.62 + "px";
  }
}

function figureOutBgColor2({ setBgColor, emoji }) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  canvas.width = canvas.height = 64;
  ctx.font = "64px serif";
  ctx.fillText(emoji, 0, 64);

  const { data } = ctx.getImageData(0, 0, 64, 64);
  const colorCounts = {};
  let maxCount = 0;
  let dominantColor = { r: 255, g: 255, b: 255 };

  for (let i = 0; i < data.length; i += 4) {
    if (data[i + 3] > 128) {
      const key = `${data[i]},${data[i + 1]},${data[i + 2]}`;
      colorCounts[key] = (colorCounts[key] || 0) + 1;
      if (colorCounts[key] > maxCount) {
        maxCount = colorCounts[key];
        dominantColor = { r: data[i], g: data[i + 1], b: data[i + 2] };
      }
    }
  }

  const color = tinycolor(dominantColor);
  setBgColor(
    color.isDark()
      ? color.lighten(35).toHexString()
      : color.darken(35).toHexString()
  );
}
