import { useEffect } from "react";
import styled from "styled-components";

import LoadingSection from "./LoadingSection";

import { useState } from "react";

import VerticalBarGraph from "./statLibrary/VerticalBarGraph";
import toMinsOrHours from "../controllers/toMinOrHours";
import shortenTheName from "../controllers/shortenTheName";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";
import verticalDataProcessorUserBased from "../controllers/statLibrary/verticalDataProcessorUserBased";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Padding = styled.div`
  padding: 25px;
  background-color: var(--translucent);
  border-radius: 5px;
  width: 100%;
`;

const BoxTitle = styled.h3`
  font-weight: 300;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  opacity: 0.7;
`;
const BoxList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 800px) {
    justify-content: flex-start;
    gap: 50px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  gap: 110px;
  width: 100%;
  padding: 0 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
const SectionTitle = styled.h3`
  margin-top: 0;
`;

let colors = [
  "#03a9f4",
  "#FF5722",
  "#4CAF50",
  "#FD8D14",
  "#FF52A2",
  "#7A9D54",
  "#6528F7",
  "#A1CCD1",
];

const Disclaimer = styled.div`
  font-size: 14px;
  padding: 25px;
  width: 100%;
  opacity: 0.5;
  margin: 30px 0;
  text-align: center;
`;

export default function LoggedInHome() {
  const { loggedInUserID, loggedInUser } = useZekToolkit();
  const [homeData, setHomeData] = useState(null);
  const { goTo } = useZekToolkit();

  useEffect(() => {
    serverLine.get("/home").then(setHomeData);
  }, []);

  if (!homeData) return <LoadingSection />;
  if (!loggedInUser) return <LoadingSection />;

  console.log(homeData, loggedInUser);

  let titleRowStyle = {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 0,
  };

  return (
    <Main>
      <Section>
        <SectionContent>
          <VerticalBarGraph
            titleRowStyle={titleRowStyle}
            // title="Daily"
            forceTimeSpanType="DATE"
            disableType={true}
            rawData={homeData.statOfMeAndFriends}
            dataProcessor={verticalDataProcessorUserBased}
          />

          <VerticalBarGraph
            maxHistory={3}
            titleRowStyle={titleRowStyle}
            // title="Monthly"
            forceTimeSpanType="WEEK"
            disableType={true}
            rawData={homeData.statOfMeAndFriends}
            dataProcessor={verticalDataProcessorUserBased}
          />

          <VerticalBarGraph
            titleRowStyle={titleRowStyle}
            // title="Monthly"
            forceTimeSpanType="MONTH"
            disableType={true}
            rawData={homeData.statOfMeAndFriends}
            dataProcessor={verticalDataProcessorUserBased}
          />

          <VerticalBarGraph
            titleRowStyle={titleRowStyle}
            // title="Yearly"
            forceTimeSpanType="YEAR"
            disableType={true}
            rawData={homeData.statOfMeAndFriends}
            dataProcessor={verticalDataProcessorUserBased}
          />

          <VerticalBarGraph
            titleRowStyle={titleRowStyle}
            title="Daily Average"
            disableFiltering={true}
            rawData={homeData.statOfMeAndFriends}
            dataProcessor={dailyAvgDataProcessor}
          />
        </SectionContent>
      </Section>

      {/* <LineGraph
        title="You Vs Friends"
        rawData={homeData.statOfMeAndFriends}
        dataProcessor={lineGraphComparisonDataProcessor}
      /> */}

      {/* <FeedPage/> */}
      {/* <StatOfEachUser statOfMeAndFriends={homeData.statOfMeAndFriends} /> */}

      <Disclaimer>
        The daily comparison stat only goes back to maximum of 10 days
      </Disclaimer>
    </Main>
  );

  function dailyAvgDataProcessor({ rawData }) {
    let proj = {};

    for (let userID in rawData) {
      let user = rawData[userID];
      let name = user.userData.name;
      let username = user.userData.username;

      let time = user.userData.dailyAvgScoreInMins;

      if (!time) continue;
      proj[userID] = {
        onClick: () => {
          goTo("/" + username)();
        },
        label: `${toMinsOrHours({
          unparsedMinutes: time,
        })} . ${shortenTheName(name)}`,
        value: time,
      };
    }

    return proj;
  }
}
