import copy from "copy-to-clipboard";
import sendMessageOutsideIframe from "./sendMessageOutsideIframe";
import universalLocalStorage from "../zekFrontendToolkit/universalLocalStorage";

export default function openLink(theLink) {
  return async () => {
    if (theLink.indexOf("http") == -1) {
      theLink = "https://" + theLink;
    }

    let isInsideExtension = await universalLocalStorage.getItem(
      "extension-login"
    );

    if (isInsideExtension) {
      sendMessageOutsideIframe("OPEN_LINK", theLink);

      // copy(theLink);
      // window.doAlert("Link Copied To Clipboard");
    } else {
      console.log("opening, ", theLink);
      window.open(theLink);
    }
  };
}
