import styled from "styled-components";
import VerticalBarGraph from "./statLibrary/VerticalBarGraph";
import HorizontalBarGraph from "./statLibrary/HorizontalBarGraph";
import getDateWithSlash from "../controllers/getDateWithSlash";
import toMinsOrHours from "../controllers/toMinOrHours";
import hozStatDataProcessor from "../controllers/hozStatDataProcessor";
import ProjectSpecificStat from "./ProjectSpecificStat";
import TimeStat from "./statLibrary/TimeStat";
import processLangName from "../controllers/processLangName";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";

export default function StatOfUser({ userStat, user, datesAndMinutes }) {
  const { showPopup, loggedInUserID } = useZekToolkit();
  if (!user) return;

  if (!userStat) return;
  // userStat = userStat.stat;

  let hideProjects = false;

  if (loggedInUserID) {
    if (loggedInUserID !== user._id) {
      if (user.hideProjectData) hideProjects = true;
    }
  } else {
    if (user.hideProjectData) hideProjects = true;
  }

  return [
    <HorizontalBarGraph
      isMinimal={true}
      title={`Stat`}
      rawData={datesAndMinutes}
      dataProcessor={hozStatDataProcessor}
    />,
    <TimeStat title={`Time Stat`} rawData={userStat} />,
    hideProjects ? null : (
      <VerticalBarGraph
        title={`Projects`}
        rawData={userStat}
        dataProcessor={verticalDataProcessorProjectBased}
      />
    ),
    <VerticalBarGraph
      title={`Languages`}
      rawData={userStat}
      dataProcessor={verticalDataProcessorLanguageBased}
    />,
  ];

  function verticalDataProcessorLanguageBased({
    dataPoints,
    startDate,
    timeSpanType,
    rawData,
  }) {
    let proj = {};

    let date = new Date(startDate.toString());

    if (timeSpanType === "EVER") {
      for (let dateString in rawData) {
        processDate(dateString);
      }
    } else {
      for (let i = 0; i < dataPoints; i++) {
        let slashDate = getDateWithSlash(date);
        processDate(slashDate);
        date.setDate(date.getDate() + 1);
      }
    }

    function processDate(slashDate) {
      if (rawData[slashDate]) {
        let item = rawData[slashDate];
        if (item.sessionData) {
          for (let sessionID in item.sessionData) {
            let session = item.sessionData[sessionID];

            if (session.languageData) {
              for (let langID in session.languageData) {
                let langObj = session.languageData[langID];
                processLangObj(langID, langObj);
              }
            }
          }
        }
      }
    }

    function processLangObj(languageID, langObj) {
      if (proj[languageID]) {
        proj[languageID].value += langObj.durationInSecs;
        proj[languageID].label = `${toMinsOrHours({
          unparsedSeconds: proj[languageID].value,
        })} . ${languageID}`;
      } else {
        proj[languageID] = {
          value: langObj.durationInSecs,
          label: `${toMinsOrHours({
            unparsedSeconds: langObj.durationInSecs,
          })} . ${processLangName(languageID)}`,
        };
      }
    }

    return proj;
  }

  function verticalDataProcessorProjectBased({
    dataPoints,
    startDate,
    timeSpanType,
    rawData,
  }) {
    let proj = {};

    let date = new Date(startDate.toString());

    if (timeSpanType === "EVER") {
      for (let dateString in rawData) {
        processDate(dateString);
      }
    } else {
      for (let i = 0; i < dataPoints; i++) {
        let slashDate = getDateWithSlash(date);
        processDate(slashDate);
        date.setDate(date.getDate() + 1);
      }
    }

    function processDate(slashDate) {
      if (rawData[slashDate]) {
        let item = rawData[slashDate];
        if (item.sessionData) {
          for (let sessionID in item.sessionData) {
            let session = item.sessionData[sessionID];

            if (!session.projectName) session.projectName = "general";

            let projectName = session.projectName;

            if (proj[projectName]) {
              proj[projectName].value += session.durationInSecs;
              proj[projectName].label = `${toMinsOrHours({
                unparsedSeconds: proj[projectName].value,
              })} . ${projectName}`;
            } else {
              proj[projectName] = {
                onClick: () => {
                  showPopup({
                    title: projectName + " Project Stat",
                    component: (
                      <ProjectSpecificStat
                        rawData={rawData}
                        projectName={projectName}
                      />
                    ),
                  });
                },
                value: session.durationInSecs,
                label: `${toMinsOrHours({
                  unparsedSeconds: session.durationInSecs,
                })} . ${projectName}`,
              };
            }
          }
        }
      }
    }

    return proj;
  }
}
