import { styled } from "styled-components";
import BrandContainer from "../BrandContainer";
import { useState } from "react";
import LoadingSection from "../LoadingSection";
import Search from "../Search";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit";
import serverLine from "../../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  gap: 25px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
`;

const Button = styled.div`
  width: 38vw;
  font-size: 21px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Input = styled.textarea`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  font-family: "Inter";
  resize: none;

  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const Title = styled.h3`
  margin-bottom: 25px;
  font-weight: 300;
`;

const SubmitButton = styled.div`
  background: var(--color);
  color: var(--bgColor);
  padding: 15px 0;
  border-radius: 25px;
  box-shadow: 0px -1px 18px 7px #0006;
  cursor: pointer;
  width: 200px;
  display: flex;
  font-size: 18px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 25px;
  left: calc((100vw - 200px) / 2);
  /* transition: 0.25s ease-out; */

  @media (max-width: 900px) {
    width: 62vw;
    padding: 20px 50px;
    /* border-radius: 15px 15px 0 0; */
    left: 19vw;
    bottom: 10px;
  }
`;

const SubContainer = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 900px) {
    width: 100vw;
    padding: 0 25px;
  }
`;

export default function FirstFollow() {
  const { refreshUser, globalState } = useZekToolkit();
  const [loading, setLoading] = useState(false);

  let { isInsideExtension } = globalState;

  if (loading) return <LoadingSection />;

  return (
    <Container>
      {isInsideExtension ? null : (
        <BrandContainer style={{ marginTop: "25px" }} />
      )}
      <SubContainer>
        <Title>Please! follow a few people</Title>
        <br />
        <Search />
        <SubmitButton onClick={save}>Done</SubmitButton>
      </SubContainer>
    </Container>
  );

  async function save() {
    let toSend = {
      changes: { onboarding: "firstFollow" },
    };

    setLoading(true);

    await serverLine.patch("/profile", toSend);
    await refreshUser();

    setLoading(false);
  }
}
