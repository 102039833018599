import Link from "./Link";
import { useEffect } from "react";
import styled from "styled-components";

import { useState } from "react";
import { FiSun } from "react-icons/fi";
import { HiMenu } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";

import {
  RiHomeFill,
  RiHomeLine,
  RiNotificationFill,
  RiNotificationLine,
  RiSearchFill,
  RiSearchLine,
  RiUserFill,
  RiUserLine,
} from "react-icons/ri";
import {
  MdLeaderboard,
  MdOutlineDarkMode,
  MdOutlineLeaderboard,
} from "react-icons/md";
import BrandContainer from "./BrandContainer";
import AccomplishmentAdder from "./AccomplishmentAdder";
import useZekToolkit from "../zekFrontendToolkit/useZekToolkit";
import serverLine from "../zekFrontendToolkit/network/serverLine";
import { CgTimer } from "react-icons/cg";

const Container = styled.div`
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 38vw;
  align-items: start;
  margin-top: 50px;
  gap: 50px;
  border-radius: 0;

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 700px) {
    width: 90vw;
    ${({ isInsideExtension }) => {
      if (isInsideExtension) return `width: 100vw;`;
    }}
    margin-top: 15px;
  }

  ${({ isInsideExtension }) => {
    if (isInsideExtension) return `zoom:90%;`;
  }}
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 25px;

  gap: 0;
  padding: 0 0;
  border-radius: 15px;
  background: var(--translucent);
  border: none;
`;

const LinkItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotifIndication = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: tomato;
  position: absolute;
  bottom: 3px;
  left: calc(50% - 4px);
`;

const LoginButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  color: var(--bgColor);
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 172px;
  border: 1px solid;
  padding: 10px 0;
  gap: 15px;
  background-color: var(--translucent);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--color);
  border: none;
  &:hover {
    transform: scale(0.9);
  }
  width: 100px;

  @media (min-width: 950px) {
    width: 200px;
  }
`;

const LinkButtonContainer = styled.div`
  display: flex;

  align-items: center;
  border-radius: 15px;
  font-size: 17px;
  cursor: pointer;
  /* background: var(--translucent); */

  width: calc(38vw / 7);
  flex: 1;
  justify-content: center;
  height: 50px;
  padding: 0;
  position: relative;

  &:hover {
    background: var(--translucent);
    color: var(--color);
  }

  ${({ isActive }) => {
    if (isActive)
      return `
      background: var(--translucent);
      color: var(--color);
    `;
  }}

  @media (max-width: 1200px) {
    width: calc(62vw / 7);
  }

  @media (max-width: 700px) {
    width: calc(90vw / 7);
  }
`;

const Button = styled.div`
  display: flex;
  height: 35px;
  font-size: 15px;
  gap: 15px;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  justify-content: center;
  padding: 0 30px;
  border: 1px solid var(--translucentHard);

  &:hover {
    background: var(--translucent);
    color: var(--color);
  }
`;

const CircularButton = styled(Button)`
  height: 35px;
  width: 35px;
  font-size: 13px;
  padding: 0 !important;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  gap: 15px;
`;

const CustomLink = styled(Link)`
  display: flex;
  flex: 1;
`;

const AddAchievement = styled.button`
  position: fixed;
  bottom: 50px;
  right: 50px;

  background: var(--translucent);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(35px);
  border: none;
  color: color;
  padding: 15px 20px;
  font-size: 18px;
  /* opacity: 0.5; */
  box-shadow: 1px 1px 20px 4px rgb(0 0 0 / 37%);
  color: var(--color);
  z-index: 555555;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

let checkNotificationInMins = 5;

export default function Header() {
  const {
    loggedInUser,
    loggedInUserID,
    colorMode,
    switchColorMode,
    showPopup,
    doGoogleLogin,
    globalState,
  } = useZekToolkit();
  const [notificationCount, setNotificationCount] = useState(0);

  let { isInsideExtension } = globalState;

  window.setNotificationCount = setNotificationCount;

  useEffect(() => {
    if (loggedInUserID) {
      if (window.notificationInterval)
        window.clearInterval(window.notificationInterval);
      fetchNotifCount();
      window.notificationInterval = setInterval(() => {
        fetchNotifCount();
      }, checkNotificationInMins * 60 * 1000);
    }
  }, [loggedInUserID]);

  function fetchNotifCount() {
    serverLine.get("/notifications/?type=count").then(setNotificationCount);
  }

  useEffect(() => {
    if (
      loggedInUserID == false &&
      window.location.pathname.indexOf("session") != -1
    ) {
      window.location.href = window.location.origin;
    }
  }, [loggedInUserID]);

  let loginButton = (
    <LoginButton onClick={doGoogleLogin}>
      <GrGoogle />
      Login
    </LoginButton>
  );

  if (loggedInUserID) loginButton = null;

  let topPart = (
    <Top>
      <Link href="/">
        <BrandContainer />
      </Link>

      <Buttons>
        {loggedInUser
          ? [
              window.innerWidth > 900 ? (
                <Link href="https://marketplace.visualstudio.com/items?itemName=upon-interactive.paratime">
                  <Button>Get Extension</Button>
                </Link>
              ) : null,

              <CircularButton
                onClick={switchColorMode}
                style={{ padding: "0 12px" }}
              >
                {colorMode == "LIGHT" ? <MdOutlineDarkMode /> : <FiSun />}
              </CircularButton>,
            ]
          : loginButton}
      </Buttons>
    </Top>
  );

  if (isInsideExtension) topPart = null;

  let containerStyle = {};

  if (isInsideExtension) containerStyle = { margin: 0 };

  let addAchievementBtn = (
    <AddAchievement onClick={openAccomplishmentAdder}>
      + Achievement
    </AddAchievement>
  );

  if (!loggedInUserID) addAchievementBtn = null;

  if (window.location.pathname !== "/") addAchievementBtn = null;

  addAchievementBtn = null;

  return (
    <Container isInsideExtension={isInsideExtension} style={containerStyle}>
      {topPart}
      {addAchievementBtn}
      {loggedInUser ? getLinks() : null}
    </Container>
  );

  function openAccomplishmentAdder() {
    showPopup({
      title: "Write",
      component: <AccomplishmentAdder />,
    });
  }

  function getLinks() {
    let data = (
      <Links>
        <Link href="/">
          <LinkButton
            isActive={window.location.pathname === "/"}
            nonActiveIcon={<RiHomeLine />}
            activeIcon={<RiHomeFill />}
          ></LinkButton>
        </Link>

        <Link href="/search">
          <LinkButton
            isActive={window.location.pathname === "/search"}
            nonActiveIcon={<RiSearchLine />}
            activeIcon={<RiSearchFill />}
          ></LinkButton>
        </Link>

        {process.env.REACT_APP_ENV_TYPE == "development" ? (
          <Link href="/create-timer">
            <LinkButton
              isActive={window.location.pathname === "/create-timer"}
              activeIcon={<CgTimer />}
              nonActiveIcon={<CgTimer />}
            >
              {notificationCount ? <NotifIndication /> : null}
            </LinkButton>
          </Link>
        ) : null}

        <Link href="/notifications">
          <LinkButton
            isActive={window.location.pathname === "/notifications"}
            activeIcon={<RiNotificationFill />}
            nonActiveIcon={<RiNotificationLine />}
          >
            {notificationCount ? <NotifIndication /> : null}
          </LinkButton>
        </Link>

        <Link href={"/" + loggedInUser.username}>
          <LinkButton
            isActive={window.location.pathname === "/" + loggedInUser.username}
            activeIcon={<RiUserFill />}
            nonActiveIcon={<RiUserLine />}
          ></LinkButton>
        </Link>

        <Link href="/leaderboard">
          <LinkButton
            isActive={window.location.pathname === "/leaderboard"}
            nonActiveIcon={<MdOutlineLeaderboard />}
            activeIcon={<MdLeaderboard />}
          ></LinkButton>
        </Link>

        <Link href="/settings">
          <LinkButton
            isActive={window.location.pathname === "/settings"}
            activeIcon={<HiMenu />}
            nonActiveIcon={<HiMenu />}
          />
        </Link>
      </Links>
    );

    return data;
  }
}

function LinkButton({ isActive, activeIcon, nonActiveIcon, children }) {
  return (
    <LinkButtonContainer>
      {isActive ? activeIcon : nonActiveIcon}
      {children}
    </LinkButtonContainer>
  );
}
