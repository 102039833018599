import CreateTimer from "../components/CreateTimer";
import EditProfilePage from "../components/EditProfilePage";
import ExtensionLogin from "../components/ExtensionLogin";
import Home from "../components/Home";
import LeaderboardPage from "../components/LeaderboardPage";
import Notifications from "../components/Notifications";
import PrivacyPolicy from "../components/PrivacyPolicy";
import PromptExtensionAuth from "../components/PromptExtensionAuth";
import Search from "../components/Search";
import SessionPage from "../components/SessionPage";
import Settings from "../components/Settings";
import UserPage from "../components/UserPage";
import ZekToolkit from "../zekFrontendToolkit/ZekToolkit";
import RouteEncapsulation from "./RouteEncapsulation";

export default function Configure() {
  let pathVsComp = {
    "/": Home,
    "/leaderboard": LeaderboardPage,
    "/settings": Settings,
    "/session": SessionPage,
    "/search": Search,
    "/privacy-policy": PrivacyPolicy,
    "/notifications": Notifications,
    "/extension-login": ExtensionLogin,
    "/edit-profile": EditProfilePage,
    "/create-timer": CreateTimer,
    "/prompt-extension-auth": PromptExtensionAuth,
    "*": UserPage,
  };

  let muiTheme = {
    palette: {
      mode: "dark",
      primary: {
        main: "#3060da",
        dark: "#1359d2",
      },
      secondary: {
        main: "#133ed6",
      },
    },
  };

  let darkTheme = {
    accentVeryLight: "#ADCCFF",
    accentLight: "#5797FF",
    mainGradient: "linear-gradient(24deg, #6D9CFF, #0063FF)",
    translucentLight: "rgb(212 226 227 / 5%)",
    foreground: "#111111",
    accentColor: "rgb(38, 162, 90)",
    bgColorDark: "#000000",
    bgColor: "#000000",
    bgColorLight: "#222222",
    bgColor2: "#000000",
    color: "#ffffff",
    translucent: "rgb(212 226 227 / 15%)",
    translucentHard: "rgba(255, 255, 255, 0.2)",
  };

  let lightTheme = {
    accentVeryLight: "#E3F0FF",
    accentLight: "#A3C8FF",
    mainGradient: "linear-gradient(24deg, #A3C8FF, #5797FF)",
    translucentLight: "rgb(255 255 255 / 50%)",
    foreground: "#333333",
    accentColor: "rgb(38, 162, 90)",
    bgColorDark: "#F8F9FA",
    bgColor: "#FFFFFF",
    bgColorLight: "#F0F3F5",
    bgColor2: "#EAECEF",
    color: "#111111",
    translucent: "rgb(0 0 0 / 7%)",
    translucentHard: "rgba(0, 0, 0, 0.1)",
  };

  return (
    <ZekToolkit
      googleAuthClientID={
        "991911447615-vkp6b69shojc7jk2evhdjcdnaqosabnr.apps.googleusercontent.com"
      }
      routes={pathVsComp}
      localServerURL={"http://localhost:8080"}
      productionServerURL={"https://backend.paratime.app"}
      muiTheme={muiTheme}
      darkTheme={darkTheme}
      lightTheme={lightTheme}
      defaultColorMode={"DARK"}
      namespace={"paratime"}
      RouteEncapsulation={RouteEncapsulation}
    />
  );
}
